import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Fade as Hamburger } from 'hamburger-react';
import {
  LayoutWrapper,
  HeaderWrapper,
  HeaderTitle,
  SectionWrapper,
  GridWrapper,
  Paragraph,
  Title,
  ListWrapper,
  ListItem,
  ListWrapperWide,
  ListItemWide,
  InnerWrapper,
  Footer,
  VideoWrapper,
  HamburgerWrapper,
  MenuWrapper,
  Menu,
  NavWrapper,
  NavItem,
} from './styles';
import Carousel from 'react-bootstrap/Carousel';
import Video from './assets/video.mp4';
import Logo from './assets/logo_white.png';
import Image3 from './assets/background_1.jpg';
import Image2 from './assets/background_2.jpg';
import Image1 from './assets/background_3.jpg';

const App = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    console.log(Video ? 'true' : 'false');
  }, []);
  return (
    <LayoutWrapper>
      <HamburgerWrapper>
        <Hamburger size={48} color="#fff" toggled={isOpen} toggle={setOpen} />
      </HamburgerWrapper>
      {isOpen && (
        <MenuWrapper>
          <Menu>
            <NavWrapper>
              <NavItem>
                <a href="#about" alt="link" onClick={() => setOpen(false)}>
                  About Us
                </a>
              </NavItem>
              <NavItem>
                <a href="#directors" alt="link" onClick={() => setOpen(false)}>
                  The Team
                </a>
              </NavItem>
              <NavItem>
                <a href="#requirements" alt="link" onClick={() => setOpen(false)}>
                  Requirements
                </a>
              </NavItem>
              <NavItem>
                <a href="#contact" alt="link" onClick={() => setOpen(false)}>
                  Contact Us
                </a>
              </NavItem>
            </NavWrapper>
          </Menu>
        </MenuWrapper>
      )}

      <HeaderWrapper>
        <VideoWrapper>
          <Carousel interval={5000} controls={false} pause={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Image1}
                alt="First slide"
                style={{ height: '100vh', objectFit: 'cover' }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Image2}
                alt="Third slide"
                style={{ height: '100vh', objectFit: 'cover' }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Image3}
                alt="Third slide"
                style={{ height: '100vh', objectFit: 'cover' }}
              />
            </Carousel.Item>
          </Carousel>
        </VideoWrapper>
        <HeaderTitle>
          <Fade bottom>
            <img className="d-block w-100" src={Logo} alt="Hopton" />
          </Fade>
        </HeaderTitle>
      </HeaderWrapper>
      <SectionWrapper background="#fff" color="#222" id="about">
        <Fade bottom>
          <InnerWrapper>
            <Title>ABOUT US</Title>
            <Paragraph>
              Hopton is a boutique investment manager specialising in real estate and venture capital. We operate in all sectors of the UK property market including commercial, residential and asset-backed operating businesses. The Hopton team has a breadth of experience in direct and indirect real estate, as well as private equity and banking. We are able to provide an end-to-end service tailored to individual partners' needs offering a full service from sourcing and investment underwriting, to asset/development management and disinvestment.
            </Paragraph>
            <GridWrapper>
              <ListWrapperWide>
                <ListItemWide>Core services</ListItemWide>
                <br />
                <ListItemWide>Venture capital/ early stage investment partnerships</ListItemWide>
                <br />
                <ListItemWide>Investment sourcing and underwriting</ListItemWide>
                <br />
                <ListItemWide>Asset and development management</ListItemWide>
              </ListWrapperWide>
            </GridWrapper>
          </InnerWrapper>
        </Fade>
      </SectionWrapper>
      <SectionWrapper background="#2199B5" color="#fff">
        <Fade bottom>
          <InnerWrapper>
            <Paragraph>
              Hopton’s property investment philosophy is focused on the property fundamentals, purchasing good quality, well-located assets with favourable supply and demand dynamics and downside risk mitigation options. Our philosophy for our venture investments is to back ambitious entrepreneurs, who have an innovative product or solution and strong business model. Our primary goal is to help entrepreneurs maximise the value of their businesses through leveraging off our own in-house skills and experience. We make early stage investments and look to play a meaningful role in helping the company in their journey through the highs and lows of growing a business.
            </Paragraph>
          </InnerWrapper>
        </Fade>
      </SectionWrapper>
      <SectionWrapper background="#fff" color="#222" id="directors">
        <InnerWrapper>
          <Fade bottom>
            <Title>The Team</Title>
            <Paragraph>
              <b>Will Clark</b> - Will has 9 years’ experience in the real estate industry, with skills in fund management, investment transactions and asset management. Since December 2019 has been working on setting up Hopton and is primarily focused on the real estate side on the business. Prior to Hopton, Will was a Fund Manager at Tesco Pension Fund, investing and managing both direct and indirect investments on a diverse fund with a value of c.£1.5bn. Prior to this, he worked at Cushman and Wakefield in the Capital Markets Team and has served in the British Army. Will holds a BSc degree in Real Estate Investment and Finance from Reading University and is a member of the Royal Institution of Chartered Surveyors.
            </Paragraph>
          </Fade>
          <Fade bottom>
            <Paragraph>
              <b>Balbir Chahal</b> – Balbir has over 25 years’ experience working in tech, private equity and banking. He is a serial entrepreneur having spearheaded a number of new business ventures which led to successful management buy-outs. He has also enjoyed a successful career at a number of leading finance houses and overseas offices having had a passion for the use of Artificial Intelligence (AI) in Banking. Balbir also started giving back to society by founding a mentoring scheme for the next generation of entrepreneurs. He was a key member of the strategy team at Cambridge Local Enterprise Partnership (LEP) and over the last 10 years he has been advising various Cambridge University spin-out ventures.  Balbir also holds a degree in Business & Finance following a scholarship at Coventry Business School and was selected for a High Potential Future Leader programme at the London Business School.
            </Paragraph>
          </Fade>
        </InnerWrapper>
      </SectionWrapper>
      <SectionWrapper background="#AEA6A5" color="#fff" id="requirements">
        <InnerWrapper>
          <Fade bottom>
            <Title>REQUIREMENTS</Title>
            <Paragraph>
              We have a series of client mandates from ‘Core to Opportunistic’ risk profiles and ability to invest across a capital structure including equity, joint ventures and debt. Our current requirements are as follows:
            </Paragraph>
          </Fade>
          <Fade bottom>
            <GridWrapper>
              <ListWrapper>
                <ListItem>Income</ListItem>
                <ListItem>£40m+ lot sizes</ListItem>
                <ListItem>South East and Major UK Cities</ListItem>
                <ListItem>Core income – NIY 6%+</ListItem>
                <ListItem>Strong tenant line up</ListItem>
                <ListItem>All sectors</ListItem>
                <ListItem>Sharia compliant tenant line up</ListItem>
              </ListWrapper>
              <ListWrapper>
                <ListItem>Value add</ListItem>
                <ListItem>£2m-£20m lot sizes</ListItem>
                <ListItem>South East and affluent regional towns</ListItem>
                <ListItem>Short leases/ regear opportunities</ListItem>
                <ListItem>Vacant/ refurbishment opportunities</ListItem>
                <ListItem>All sectors</ListItem>
              </ListWrapper>
              <ListWrapper>
                <ListItem>Opportunistic</ListItem>
                <ListItem>£2m-£20m lot sizes</ListItem>
                <ListItem>South East and affluent regional towns</ListItem>
                <ListItem>Development opportunities</ListItem>
                <ListItem>Operating business</ListItem>
                <ListItem>Distressed sales</ListItem>
                <ListItem>Mezzanine</ListItem>
              </ListWrapper>
            </GridWrapper>
          </Fade>
        </InnerWrapper>
      </SectionWrapper>
      <SectionWrapper background="#fff" color="#222" id="contact">
        <Fade bottom>
          <InnerWrapper>
            <Title>CONTACT US</Title>
            <Paragraph>
              For more information about Hopton, our Track Record and Case Studies please contact us:
            </Paragraph>
            <Fade bottom>
              <GridWrapper>
                <ListWrapper>
                  <ListItem>EMAIL</ListItem>
                  <ListItem>enquiries@hopton.co.uk</ListItem>
                </ListWrapper>
                <ListWrapper>
                  <ListItem>TELEPHONE</ListItem>
                  <ListItem>020 7349 8455</ListItem>
                </ListWrapper>
                <ListWrapper>
                  <ListItem>VISIT US</ListItem>
                  <ListItem>2-4 Noel Street</ListItem>
                  <ListItem>London</ListItem>
                  <ListItem>W1F 8GB</ListItem>
                </ListWrapper>
              </GridWrapper>
            </Fade>
          </InnerWrapper>
        </Fade>
      </SectionWrapper>
      <SectionWrapper background="#222" color="#fff">
        <InnerWrapper>
          <Footer>
            Hopton Properties is the trading name of Hopton Property Group Limited 266 - 268 Stratford Road Shirley Solihull West Midlands B90 3AD. Incorporated in England & Wales No. 12142459. Tel 07584492984. This e-mail message or any documents accompanying it may contain confidential information belonging to the sender which is legally privileged. If you are not the intended recipient you are hereby notified that any disclosure, copying, distribution or the taking of any action in reliance on or regarding the contents of this e-mail information is strictly prohibited. If the message is received by anyone other than the addressee, please return the message to the sender by replying to it and then delete the message from your computer. Internet e-mails are not necessarily secure. Hopton Property Group Limited do not accept responsibility for changes made to this message after it was sent. Whilst all reasonable care has been taken to avoid the transmission of viruses, it is the responsibility of the recipient to ensure that the onward transmission, opening or use of this message and any attachments will not adversely affect its systems or data. No responsibility is accepted by Hopton Property Group Limited in this regard and the recipient should carry out such virus and other checks as it considers appropriate.
          </Footer>
        </InnerWrapper>
      </SectionWrapper>
    </LayoutWrapper>
  );
};

export default App;
