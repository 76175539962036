import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  display: block;
  padding: 0;
`;

export const HamburgerWrapper = styled.div`
  position: fixed;
  z-index: 6;
  padding: 20px;
  mix-blend-mode: difference;
`;

export const MenuWrapper = styled.div`
  position: fixed;
  z-index: 5;
  height: 100vh;
  width: 100vw;
  background: #000;
`;

export const Menu = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & ul {
    color: #fff;
  }
`;

export const HeaderWrapper = styled.div`
  display: block;
  height: 100vh;
  position: relative;
  z-index: 1;
`;

export const VideoWrapper = styled.div`
  & > #bgvid {
    object-fit: cover;
    top: 0;
    left: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }
`;

export const HeaderTitle = styled.div`
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 300px;
  transform: translate(-50%, -50%);
  font-size: 5em;
  color: #fff;
  & > img {
    width: 100%;
  }
`;
export const SectionWrapper = styled.div(
  ({ background, color }) => `
  padding: 50px 20px;
  background: ${background};
  color: ${color};
  @media screen and (min-width: 768px){
    padding: 50px 100px;
  }
`
);

export const InnerWrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    max-width: 968px;
  }
`;

export const Title = styled.h2`
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 0;
  @media screen and (min-width: 768px) {
    font-size: 2.75em;
  }
`;

export const Paragraph = styled.div`
  padding: 20px 0;
  font-size: 1em;
  text-align: justify;
  font-weight: 400;
  @media screen and (min-width: 768px) {
    font-size: 1.2em;
  }
`;

export const GridWrapper = styled.div`
  padding: 20px 0;
  font-size: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ListWrapper = styled.ul`
  display: block;
  padding: 0;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 200px;
  }
`;

export const ListWrapperWide = styled.ul`
  display: block;
  padding: 0;
  width: 100%;
  @media screen and (min-width: 768px) {
    max-width: 600px;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 5px;
  font-size: 1em;
  &:first-child {
    font-size: 1.2em;
    font-weight: bold;
  }
`;

export const ListItemWide = styled.li`
  list-style: none;
  margin-bottom: 5px;
  font-size: 1em;
  &:first-child {
    font-size: 1.25em;
    font-weight: bold;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.25em;
      &:first-child {
      font-size: 1.5em;
    }
  }
`;

export const NavWrapper = styled.ul`
  display: block;
  padding: 0;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 200px;
  }
`;

export const NavItem = styled.li`
  list-style: none;
  margin-left: 0;
  margin-bottom: 5px;
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  transition: all ease-in-out 0.25s;
  &:hover {
    margin-left: -20px;
  }

  & > a {
    text-decoration: none;
    color: #fff;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: block;
  font-size: 10px;
`;
